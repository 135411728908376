import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { AppSettings, commonImgPath } from '@/shared/app-common';

import styles from './index.module.less';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import { Carousel } from 'antd';
import styled from 'styled-components';
import Image from 'next/image';
// import { imgUrl } from '@/shared/utils';
import { HomePreviewDataModel } from '@/types/home';
import { useWindowSize } from 'react-use';
import { cn } from '@/lib/utils';

export const CustomCarousel = styled(Carousel)`
  .slick-dots li.slick-active button {
    background-color: #725cff; /* 设置当前指示点的颜色 */
  }

  .slick-dots li button {
    background-color: #d9d9d9; /* 设置未激活指示点的颜色 */
  }
`;

const Card = ({
  title,
  desc,
  imgPath,
  link,
  isRight,
  isMobile = false,
}: {
  title?: string;
  desc?: string;
  imgPath?: string;
  link?: string;
  isRight?: boolean;
  isMobile?: boolean;
}) => {
  const router = useRouter();
  return (
    <div
      onClick={() => link && router.push(link)}
      className={styles.card}
      style={{ backgroundImage: `url(${imgPath})` }}
    >
      <h3 className={styles.title} dangerouslySetInnerHTML={{ __html: title }} />
      <p
        className={styles.desc}
        style={{ width: isMobile ? '80%' : '50%' }}
        dangerouslySetInnerHTML={{ __html: desc }}
      />
    </div>
  );
};

const MemoizedCard = memo(Card);

const DetailShowContent = ({ preViewData }: { preViewData?: HomePreviewDataModel }) => {
  const { t } = useTranslation('common');
  const [isVisible, setIsVisible] = useState(false);
  const divRef = useRef<HTMLDivElement>(null);
  const { width } = useWindowSize();

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 0.1 },
    );

    if (divRef.current) {
      observer.observe(divRef.current);
    }

    return () => observer.disconnect();
  }, []);

  const items = useMemo(() => {
    if (preViewData?.meetsInfo?.dataList?.length > 0) return preViewData?.meetsInfo?.dataList;

    return [
      {
        title: t('Stay on Top of Your Competition'),
        desc: t(
          "Want to track your competitors' every move on Instagram? Monitor their follows, likes, and engagement. Stay one step ahead of your competition. Make smarter decisions and gain the competitive edge!",
        ),
        imgPath: commonImgPath + '/c9bf9e57-1685-4c89-bafb-ff5af830be8a.webp',
        isRight: true,
        link: `/instagram-tracker`,
      },
      {
        title: t('Track Unusual Instagram Activity'),
        desc: t(
          `Sudden spikes in followers or unusual following behavior can say a lot. Dolphin Radar brings public Instagram activity into focus—so you can better understand what's going on.`,
        ),
        imgPath: commonImgPath + '/550e8400-e29b-41d4-a716-446655440000.webp',
        isRight: true,
        link: `/catch-instagram-cheaters`,
      },
      {
        title: t('Find the Perfect Influencer for Your Brand'),
        desc: t(
          `Access real-time data on 10,000+ Instagram influencer. Track growth, analyze tags, and spot trends to connect with influencers who truly fit your brand.`,
        ),
        imgPath: commonImgPath + '/Group+427320676%402x.webp',
        isRight: true,
        link: `/top-instagram`,
      },
      {
        title: t('Explore Instagram activity from public accounts'),
        desc: t(
          'With Dolphin Radar, you can view public interactions, likes, followers, and recent follow activity — helping you stay informed while keeping your browsing private.',
        ),
        imgPath: commonImgPath + '/f47ac10b-58cc-4372-a567-0e02b2c3d479.webp',
        link: `/view-instagram-anonymously`,
      },

      {
        title: t('Instagram Viewing Made Easy'),
        desc: t(
          'Download Instagram Stories and Highlights discreetly with Dolphin Radar. Access public content smoothly, without connecting your own account.',
        ),
        imgPath: commonImgPath + '/6ba7b810-9dad-11d1-80b4-00c04fd430c8.webp',
        isRight: true,
        link: `/download-from-instagram`,
      },
      {
        title: t('Uncover Instagram Influencer Gossip'),
        desc: t(
          `View public interactions between creators, celebs, or that one suspicious duo you’ve been watching. We won’t tell if you won’t.`,
        ),
        imgPath: commonImgPath + '/1b9d6bcd-bbfd-4b2d-9b5d-ab8dfbbd4bed.webp',
        isRight: true,
        link: `/instagram-influencer-trends`,
      },
    ];
  }, [preViewData]);

  const getContent = useMemo(() => {
    return (
      <div className={cn(styles.content, 'mt-[6.25rem]')}>
        <h2>{preViewData?.meetsInfo?.title ?? t('WhyDoYouNeedDolphinRadar')}</h2>
        {preViewData?.meetsInfo?.desc?.length > 0 ? (
          <div className={styles.text}>
            <p>{preViewData?.meetsInfo?.desc}</p>
          </div>
        ) : (
          <div className={styles.text}>
            <p>
              {t('Are you curious about those special Instagram accounts on your watchlist?')}{' '}
              {t('WhyDoYouNeedDolphinRadar_Desc')}
            </p>
          </div>
        )}
        <div ref={divRef} className={styles.topImageContainer}>
          {isVisible && (
            <Image
              src={
                preViewData?.meetsInfo?.imgPath ?? commonImgPath + '/home_detail_show_top_bg_3.webp'
              }
              alt={'detail_show'}
              layout="responsive"
              width={300}
              height={400}
              className={styles.topImage}
            />
          )}
        </div>
        <div className={styles.bottomContainer}>
          <div className={styles.h2}>
            {preViewData?.meetsInfo?.title ?? t('WhyDoYouNeedDolphinRadar')}
          </div>
          {preViewData?.meetsInfo?.desc?.length > 0 ? (
            <div className={styles.h2text}>
              <p>{preViewData?.meetsInfo?.desc}</p>
            </div>
          ) : (
            <div className={styles.h2text}>
              <p>
                {t('Are you curious about those special Instagram accounts on your watchlist?')}{' '}
                {t('WhyDoYouNeedDolphinRadar_Desc')}
              </p>
            </div>
          )}
        </div>
        <div className={classNames([styles.bottomContainerPC])}>
          <div className={styles.leftSubContainer}>
            <div className="bg-[#ECF1FF] text-[#8377ED] px-6 py-1 rounded-full text-lg font-black mb-8">
              {t('For Marketing')}
            </div>
            {width > 900 ? (
              <div className={styles.cardContainer}>
                {items.slice(0, 3).map((item, index) => (
                  <MemoizedCard key={index} {...item} />
                ))}
              </div>
            ) : (
              <div className={styles.mobileCardContainer}>
                <CustomCarousel>
                  {items.slice(0, 3).map((item, index) => (
                    <MemoizedCard key={index} {...item} isMobile={true} />
                  ))}
                </CustomCarousel>
              </div>
            )}
          </div>

          <div className={styles.leftSubContainer}>
            <div className="bg-[#E4FAE3] text-[#55BC54] px-6 py-1 rounded-full text-lg font-black mb-8">
              {t('For Individuals')}
            </div>
            {width > 900 ? (
              <div className={styles.cardContainer}>
                {items.slice(3, 6).map((item, index) => (
                  <MemoizedCard key={index} {...item} />
                ))}
              </div>
            ) : (
              <div className={styles.mobileCardContainer}>
                <CustomCarousel>
                  {items.slice(3, 6).map((item, index) => (
                    <MemoizedCard key={index} {...item} isMobile={true} />
                  ))}
                </CustomCarousel>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }, [items, preViewData, isVisible, width]);

  return <>{getContent}</>;
};

export default DetailShowContent;
